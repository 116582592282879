<script>
import { useStore } from 'vuex'
import {computed, onMounted, reactive, toRefs} from 'vue'

const stepName = 'DiagnosedLastThreeYears'

const keys = [
  'Heart Attack',
  'Stroke',
  'Congestive Heart Failure',
  'Kidney of Liver Failure',
  'None'
]

export default {
  name: stepName,

  emits: ['next-step'],

  setup(_, {emit}) {
    const store = useStore()

    const state = reactive({
      heart_attack: false,
      stroke: false,
      congestive_heart_failure: false,
      kidney_or_liver_failure: false,
      none: true
    })

    const anyConditionsFlagged = computed(() => state.heart_attack || state.stroke || state.congestive_heart_failure || state.kidney_or_liver_failure)

    const setAnswer = () => {
      store.commit('SET_IS_LOADING', true)

      const condition_fields = [
        {field: 'heart_attack', value: state.heart_attack ? 1 : 0},
        {field: 'stroke', value: state.stroke ? 1 : 0},
        {field: 'congestive_heart_failure', value: state.congestive_heart_failure ? 1 : 0},
        {field: 'kidney_or_liver_failure', value: state.kidney_or_liver_failure ? 1 : 0},
        {field: 'not_diagnosed_with_conditions', value: state.none ? 1 : 0}
      ]

      store.commit('SET_FIELDS', condition_fields)
      emit('next-step', stepName)
    }

    const resetConditions = () => {
      state.heart_attack = false
      state.stroke = false
      state.congestive_heart_failure = false
      state.kidney_or_liver_failure = false
    }

    const checkFlaggedConditions = () => {
      if (anyConditionsFlagged.value) {
        state.none = false
      }

      if (!anyConditionsFlagged.value) {
        state.none = true
      }
    }

    const handler = (noneSelected) => {
      if (noneSelected) {
        resetConditions()
      }

      checkFlaggedConditions()
    }

    const toggleCheckBox = (id) => {
      state[id] = !state[id]

      if (id === 'none') {
        resetConditions()
      }

      checkFlaggedConditions()
    }

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    return {
      handler,
      toggleCheckBox,
      keys,
      setAnswer,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div>
    <div class="row checkbox-container-row">
      <div class="box">
        <div class="checkbox-container">
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="heart_attack" @change="handler(false)">
            <label class="checkbox-label" @click="toggleCheckBox('heart_attack')">{{keys[0]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="stroke" @change="handler(false)">
            <label class="checkbox-label" @click="toggleCheckBox('stroke')">{{keys[1]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="congestive_heart_failure" @change="handler(false)">
            <label class="checkbox-label" @click="toggleCheckBox('congestive_heart_failure')">{{keys[2]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="kidney_or_liver_failure" @change="handler(false)">
            <label class="checkbox-label" @click="toggleCheckBox('kidney_or_liver_failure')">{{keys[3]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="none" @change="handler(true)">
            <label class="checkbox-label" @click="toggleCheckBox('none')">{{keys[4]}}</label>
          </div>
        </div>

        <button
          class="main-button main-button-w mt-lg"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
//reusing treated or prescribed last five years styling
@import '../../../assets/styles/component_styles/questionnaire/treated_or_prescribed_last_five_years';
</style>